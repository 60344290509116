export const carRecordFormVO = function () {
  return {
    department: {
      id: null,
      name: ''
    },
    carInfo: {
      id: null,
      fullName: ''
    },
    managerUser: {
      id: null,
      name: ''
    },
    executeUser: {
      id: null,
      name: ''
    },
    startTime: '', //任务开始时间
    endTime: '', //任务结束时间
    summary: '', //行车记录概要
    mileage: '', //行车公里
    status: 'NOT',
    type: 'GIVEN',
    dateCreated: '', //制定时间
    instanceId: '',
    systemModule: 'damage_report'
  }
}