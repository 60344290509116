<template>
  <a-spin :spinning="ifSub">
    <a-form :form="form">
      <a-form-item label="制定人"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <a-tree-select
          v-decorator="[
                'managerUser.id',
                {
                  validateTrigger: ['change'],
                  rules: [{
                    type: 'string',
                    required: true,
                    message: '请选择制定人',
                  }]
                }
              ]"
          treeDefaultExpandAll
          treeNodeFilterProp="title"
          style="width: 100%"
          :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
          :treeData="orgUserTreeData"
          placeholder="请选择制定人"
        />
      </a-form-item>
      <a-form-item label="执行人"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <a-tree-select
          v-decorator="[
                'executeUser.id',
                {
                  validateTrigger: ['change'],
                  rules: [{
                    type: 'string',
                    required: true,
                    message: '请选择执行人',
                  }]
                }
              ]"
          treeDefaultExpandAll
          treeNodeFilterProp="title"
          style="width: 100%"
          :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
          :treeData="orgUserTreeData"
          placeholder="请选择执行人"
        />
      </a-form-item>
      <a-form-item label="执行车辆"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <a-select v-decorator="['carInfo.id',{rules: [{ required: true, message: '请选择车辆信息' }]}]"
                  allowClear placeholder="请选择车辆信息">
          <a-select-option v-for="item in carList" :key="item.id" :value="item.id">
            {{item.platNumber}}({{item.status==='FREE'?'空闲中':item.status==='GOING'?'执行中':'离线'}})
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="计划执行时间"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <a-range-picker style="width: 100%;"
                        :showTime="{ format: 'HH:mm:ss' }"
                        format="YYYY-MM-DD HH:mm:ss"
                        v-decorator="['executeTime',{rules: [{ required: true, message: '请选择执行日期' }]}]"
                        :placeholder="['开始日期', '结束日期']"
                        @change="onTimeChange"/>
      </a-form-item>
      <a-form-item label="执行内容"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <a-input v-decorator="['summary']" type="textarea" rows="3" placeholder="请输入执行内容"></a-input>
      </a-form-item>
      <a-form-item label="制定时间"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <a-date-picker disabled style="width: 100%" :showTime="{ format: 'YYYY-MM-DD HH:mm:ss' }"
                       format="YYYY-MM-DD HH:mm:ss"
                       v-decorator="['dateCreated',{rules: [{ required: true, message: '请选择' }]}]"
                       placeholder="请选择"></a-date-picker>
      </a-form-item>
    </a-form>
  </a-spin>
</template>

<script>
  import { carRecordFormVO } from './common/carRecordFormVO'
  import entityCURDCallback from '../../../common/mixins/entityCURDCallback'
  import SERVICE_URLS from '@/api/service.url'
  import { statusList } from './common/common'
  import moment from 'moment'

  export default {
    name: 'CarRecordForm',
    mixins: [entityCURDCallback],
    components: {},
    props: {
      type: {
        type: String,
        default: 'add'
      },
      instanceId: {
        type: String,
        default: ''
      },
      systemModule: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        labelCol: { span: 5 },
        wrapperCol: { span: 18 },
        entityBaseUrl: SERVICE_URLS.car.carRecordApi,
        initFormItem: carRecordFormVO(),
        showFormItem: false,
        statusList: statusList(),
        orgUserTreeData: [],
        carList: []
      }
    },
    computed: {
      currentUser () {
        return this.$store.getters.currentUser
      }
    },
    mounted () {
      this.loadCarInfoList()
      this.formItem.instanceId = this.instanceId
      this.formItem.systemModule = this.systemModule
      if (this.type === 'add') {
        this.loadCheckUserList()
        this.form.setFieldsValue({
          dateCreated: moment(new Date())
        })
      }
    },
    methods: {
      loadCheckUserList () {
        this.$http(this, {
          url: SERVICE_URLS.organization.orgUserTree,
          data: {
            authPrefix: 'car_',
            chooseType: 'RADIO',
          },
          noTips: true,
          success: (data) => {
            this.orgUserTreeData = data.body
            if (this.type === 'add') {
              /*默认设置执行人*/
              this.$nextTick(() => {
                const managerUserKey = this.getSelectKey(this.orgUserTreeData, this.currentUser.id)
                if (managerUserKey) {
                  this.form.setFieldsValue({
                    'managerUser.id': managerUserKey
                  })
                }
              })
            } else {
              /*TODO 1.为啥在和处理？*/
              /*处理下拉树KEY*/
              var managerUserKey = this.getSelectKey(this.orgUserTreeData, this.formItem.managerUser.id)
              var executeUserKey = this.getSelectKey(this.orgUserTreeData, this.formItem.executeUser.id)
              if (managerUserKey) {
                this.form.setFieldsValue({
                  'managerUser.id': managerUserKey
                })
              }
              if (executeUserKey) {
                this.form.setFieldsValue({
                  'executeUser.id': executeUserKey
                })
              }
            }
          }
        })
      },
      loadCarInfoList () {
        this.$http(this, {
          url: SERVICE_URLS.car.carInfoApi.select,
          noTips: true,
          success: (data) => {
            this.carList = data.body
          }
        })
      },
      getSelectKey (list, id) {
        var key
        for (let i = 0; i < list.length; i++) {
          const item = list[i]
          const tempKey = item.key
          if (tempKey.substring('USER_') !== -1 && Number(tempKey.split('_')[2]) === id) {
            key = tempKey
            return key
          } else if (item.children.length > 0) {
            key = this.getSelectKey(item.children, id)
          }
          if (key) return key
        }
      },
      setFields (values) {
        Object.assign(this.formItem, values)
        /*TODO 2.为啥在和处理？*/
        this.loadCheckUserList()
        this.form.setFieldsValue({
          'carInfo.id': this.formItem.carInfo.id,
          executeTime: [moment(this.formItem.planStartTime), moment(this.formItem.planEndTime)],
          summary: this.formItem.summary,
          dateCreated: moment(this.formItem.dateCreated)
        })
      },
      setVOFields (values) {
        var managerUserId = values.managerUser.id.split('_')[2]
        var executeUserId = values.executeUser.id.split('_')[2]
        this.formItem.managerUser.id = managerUserId
        this.formItem.executeUser.id = executeUserId
        this.formItem.carInfo.id = values.carInfo.id
        this.formItem.planStartTime = values.executeTime[0]
        this.formItem.planEndTime = values.executeTime[1]
        this.formItem.summary = values.summary
        this.formItem.dateCreated = values.dateCreated
      },
      addSuccessCallback (data) {
        const managerUserKey = this.getSelectKey(this.orgUserTreeData, this.currentUser.id)
        if (managerUserKey) {
          this.form.setFieldsValue({
            'managerUser.id': managerUserKey
          })
        }
        this.form.setFieldsValue({
          'dateCreated': moment(new Date())
        })
        // 添加成功之后Form内部可做的回调
      },
      addErrorCallback (e) {
        // 添加失败之后Form内部可做的回调
        // this.form.resetFields()
      },
      editSuccessCallback (data) {
        // 修改成功之后Form内部可做的回调
      },
      editErrorCallback (e) {
        // 修改失败之后Form内部可做的回调
        // this.form.resetFields()
      },
      //////////////////////////////////////////////////////
      onTimeChange (date, dateString) {
        this.formItem.planStartTime = moment(dateString[0])
        this.formItem.planEndTime = moment(dateString[1])
      }
    }
  }
</script>

<style scoped>

</style>